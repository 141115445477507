import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import ContactServiceLevelsMultiSelect from '../../components/contact-service-levels-multi-select';
import ServiceLevelSelect from '../../components/service-level-select';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';

const ServiceLevelConfigCard = ({
  mode,
}: {
  readonly mode: OrganizationPageMode;
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext<OrganizationFormValues>();
  return (
    <Stack direction="row" gap={2}>
      <Card sx={{ flex: 3, minHeight: '200px', ...styles.card }}>
        <CardContent sx={styles.cardContent}>
          <Stack gap={2}>
            <Typography variant="h6">Service levels</Typography>
            <Stack gap={2}>
              <Stack gap={2} flex={1} direction="row">
                <ContactServiceLevelsMultiSelect
                  control={control}
                  disabled={mode === OrganizationPageMode.VIEW}
                />
                <ServiceLevelSelect
                  name="defaultServiceUuid"
                  control={control}
                  label="Default service level"
                  mode={mode}
                />
              </Stack>
              <Stack
                gap={2}
                flex={1}
                sx={{
                  borderColor: theme.palette.concreteGrey[30],
                  borderWidth: 1,
                  borderStyle: 'solid',
                }}
              >
                <Stack p={2} gap={2}>
                  <Stack>
                    <Typography fontWeight="medium">EDI defaults</Typography>
                    <Typography
                      variant="caption"
                      color={theme.palette.concreteGrey[50]}
                    >
                      Default service levels to apply to incoming EDI orders
                    </Typography>
                  </Stack>
                  <Stack gap={2}>
                    <Stack direction="row" gap={2}>
                      <ServiceLevelSelect
                        name="defaultInboundEdiServiceUuid"
                        control={control}
                        label="Default service level for orders with inbound stop only"
                        mode={mode}
                      />
                      <ServiceLevelSelect
                        name="defaultOutboundEdiServiceUuid"
                        control={control}
                        label="Default service level for orders with outbound stop only"
                        mode={mode}
                      />
                    </Stack>
                    <Stack direction="row" gap={2}>
                      <ServiceLevelSelect
                        name="defaultInboundOutboundEdiServiceUuid"
                        control={control}
                        label="Default service level for orders with inbound and outbound stop"
                        mode={mode}
                      />
                      <Stack flex={1} />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ServiceLevelConfigCard;
