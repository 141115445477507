import { useEffect, useState } from 'react';
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
} from 'react-hook-form';
import {
  type CreateOrganizationInput,
  OrganizationType,
  useCreateOrganizationMutation,
} from '../../generated/graphql';
import { OrganizationFormContextProvider } from './contexts/organization-form-context';
import { OrganizationPageMode, type OrganizationPageProps } from './enums';
import OrganizationPageForm from './form/organization-page-form';
import useOrganizationForm from './form/use-organization-form';
import { isNil } from 'lodash';
import { CircularProgress } from '@mui/material';
import { useOrganizationData } from './hooks/use-organization-data';
import { type OrganizationFormValues } from './form/types';

const OrganizationPage = (props: OrganizationPageProps) => {
  const { types, loading } = useOrganizationData(props.uuid);

  const [selectedTypes, setSelectedTypes] = useState<OrganizationType[]>([
    OrganizationType.Customer,
  ]);

  useEffect(() => {
    if (!isNil(types)) {
      setSelectedTypes(types);
    }
  }, [types]);

  const { form, isFieldVisible, isFieldRequired } = useOrganizationForm({
    organizationTypes: selectedTypes,
  });

  const [createOrganization, { loading: createOrganizationLoading }] =
    useCreateOrganizationMutation({
      onCompleted: () => {
        // show success snackbar and move to edit mode/go back to the table.
      },
      onError: () => {
        // show error snackbar saying error saving contact
      },
    });

  const onSubmit: SubmitHandler<OrganizationFormValues> = async (
    data: OrganizationFormValues,
  ) => {
    if (props.mode === OrganizationPageMode.CREATE) {
      const createInput: Required<CreateOrganizationInput> = {
        email: data.email,
        name: data.name,
        referenceNumber: data.referenceNumber,
        types: data.types,
        createCustomerInput: data.types.includes(OrganizationType.Customer)
          ? {
              defaultUseTariff: true,
              invoiceOrOrderIdentifier: data.invoiceOrOrderIdentifier ?? '',
              isPrepaidOnly: true,
              serviceUuids: data.serviceUuids ?? [],
              showEdiReferenceNumbersOnOrderPage:
                data.showEdiReferenceNumbersOnOrderPage ?? false,
              useCustomVehicleTypes: data.useCustomVehicleTypes ?? false,
              vehicleTypeIds: data.vehicleTypeIds ?? [],
            }
          : null,
        ediApplicationId: data.ediApplicationId,
        ediContactCode: data.ediContactCode,
        isaId: data.isaId,
        stediPartnerId: data.stediPartnerId,
      };

      await createOrganization({
        variables: {
          input: createInput,
        },
      });
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const onError: SubmitErrorHandler<OrganizationFormValues> = (errors) => {
    // show an error snackbar
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FormProvider {...form}>
      <OrganizationFormContextProvider
        value={{ isFieldVisible, isFieldRequired }}
      >
        <OrganizationPageForm
          {...props}
          setSelectedOrganizationTypes={(typesToUse: OrganizationType[]) => {
            setSelectedTypes(typesToUse);
          }}
          onSubmit={form.handleSubmit(onSubmit, onError)}
        />
      </OrganizationFormContextProvider>
    </FormProvider>
  );
};

export default OrganizationPage;
