import { isNil } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { OrganizationPageMode, type OrganizationPageProps } from '../enums';
import { type OrganizationFormValues } from './types';
import { v4 } from 'uuid';
import {
  OrganizationType,
  useOrganizationLazyQuery,
} from '../../../generated/graphql';

const useLoadOrganizationForm = () => {
  const [getOrganization, { loading }] = useOrganizationLazyQuery();

  const form = useFormContext<OrganizationFormValues>();

  const initializeNewOrganizationForm = async () => {
    const organizationValues: OrganizationFormValues = {
      uuid: v4(),
      name: '',
      types: [OrganizationType.Customer],
      referenceNumber: '',
      email: '',
      defaultContactPersonUuid: null,
      isaId: null,
      ediContactCode: null,
      stediPartnerId: null,
      ediApplicationId: null,
      showEdiReferenceNumbersOnOrderPage: null,
      defaultAddressUuid: null,
      defaultBusinessDivisionUuid: null,
      invoiceOrOrderIdentifier: null,
      defaultServiceUuid: null,
      defaultInboundEdiServiceUuid: null,
      defaultOutboundEdiServiceUuid: null,
      defaultInboundOutboundEdiServiceUuid: null,
      serviceUuids: null,
      defaultUseTariff: null,
      isPrepaidOnly: null,
      defaultPointToPointTariffChainId: null,
      defaultFuelSurcharge: null,
      defaultDimFactor: null,
      defaultLineHaulTariffChainId: null,
      defaultOrdinaryTariffChainId: null,
      defaultTransferTariffChainId: null,
      useCustomVehicleTypes: null,
      vehicleTypeIds: null,
      referenceNumberLabels: null,
    };

    form.reset(organizationValues);
  };

  const initializeExistingOrganizationForm = async ({
    organizationUuid,
  }: {
    organizationUuid: string;
  }) => {
    const result = await getOrganization({
      variables: { input: { uuid: organizationUuid } },
    });
    const { data } = result;

    // Return early if no data is available
    if (isNil(data?.organization)) {
      return;
    }

    // First determine the organization type
    const {
      types,
      uuid: orgUuid,
      name,
      referenceNumber,
      email,
      defaultContactPerson,
      isaId,
      ediContactCode,
      ediApplicationId,
      stediPartnerId,
      customerFields,
    } = data.organization;

    // Create the appropriate form values based on types
    const organizationValues: OrganizationFormValues = {
      types,
      uuid: orgUuid,
      name,
      referenceNumber: referenceNumber ?? '',
      email: email ?? '',
      defaultContactPersonUuid: defaultContactPerson?.uuid ?? null,
      defaultAddressUuid: customerFields?.defaultAddressUuid ?? null,
      defaultBusinessDivisionUuid: customerFields?.businessDivisionUuid ?? null,
      isaId: isaId ?? null,
      ediContactCode: ediContactCode ?? null,
      stediPartnerId: stediPartnerId ?? null,
      ediApplicationId: ediApplicationId ?? null,

      // customer specific fields
      invoiceOrOrderIdentifier:
        customerFields?.invoiceOrOrderIdentifier ?? null,
      defaultInboundEdiServiceUuid:
        customerFields?.defaultInboundEdiServiceUuid ?? null,
      defaultOutboundEdiServiceUuid:
        customerFields?.defaultOutboundEdiServiceUuid ?? null,
      defaultInboundOutboundEdiServiceUuid:
        customerFields?.defaultServiceUuid ?? null,
      serviceUuids: isNil(customerFields?.services)
        ? null
        : (customerFields?.services.map(
            (s: { __typename?: 'CustomerServiceEntity'; uuid: string }) =>
              s.uuid,
          ) ?? null),
      defaultServiceUuid: customerFields?.defaultServiceUuid ?? null,
      defaultPointToPointTariffChainId:
        customerFields?.defaultPointToPointTariffChainId ?? null,
      defaultFuelSurcharge: customerFields?.defaultFuelSurcharge ?? null,
      defaultDimFactor: customerFields?.defaultDimFactor ?? null,
      defaultLineHaulTariffChainId:
        customerFields?.defaultLineHaulTariffChainId ?? null,
      defaultOrdinaryTariffChainId:
        customerFields?.defaultOrdinaryTariffChainId ?? null,
      defaultTransferTariffChainId:
        customerFields?.defaultTransferTariffChainId ?? null,
      showEdiReferenceNumbersOnOrderPage:
        customerFields?.showEdiReferenceNumbersOnOrderPage ?? null,
      defaultUseTariff: customerFields?.defaultUseTariff ?? null,
      isPrepaidOnly: customerFields?.isPrepaidOnly ?? null,
      useCustomVehicleTypes: customerFields?.useCustomVehicleTypes ?? null,
      vehicleTypeIds: customerFields?.vehicleTypes.map((vt) => vt.id) ?? null,
      referenceNumberLabels: isNil(customerFields?.referenceNumberLabels)
        ? null
        : (customerFields?.referenceNumberLabels.map(
            (label: {
              __typename?: 'CustomerReferenceNumberLabelEntity';
              name: string;
            }) => ({ name: label.name }),
          ) ?? null),
    };

    form.reset(organizationValues);
  };

  const initializeForm = async ({ mode, uuid }: OrganizationPageProps) => {
    if (mode === OrganizationPageMode.CREATE) {
      await initializeNewOrganizationForm();
    } else if (!isNil(uuid)) {
      await initializeExistingOrganizationForm({ organizationUuid: uuid });
    }
  };

  return {
    initializeForm,
    loading,
  };
};

export { useLoadOrganizationForm };
