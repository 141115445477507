import { type QueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash';
import {
  type MeQuery,
  type MeQueryVariables,
  useMeQuery,
} from '../../generated/graphql';

const useMe = (baseOptions?: QueryHookOptions<MeQuery, MeQueryVariables>) => {
  const {
    data: meData,
    loading: meDataLoading,
    refetch: refetchMeData,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'cache-first',
    ...baseOptions,
  });

  const user = meData?.me;
  const companyData = user?.company;
  const companyConfiguration = user?.company?.configuration;

  return {
    loading: meDataLoading,
    error: meError,
    user,
    meData,
    userUuid: user?.uuid,
    email: user?.email,
    createdAt: user?.createdAt,
    companyData,
    segment: companyData?.segment,
    isCourier: companyData?.isCourier,
    companyUuid: companyData?.uuid,
    companyName: companyData?.name,
    companyTimezone: companyData?.timeZone,
    companyConfiguration,
    defaultLatitude: companyData?.defaultAddress?.latitude,
    defaultLongitude: companyData?.defaultAddress?.longitude,
    defaultWeightUnits: companyData?.defaultWeightUnits,
    defaultMeasurementUnits: companyData?.defaultMeasurementUnits,
    defaultDimFactor: companyData?.configuration?.defaultDimFactor,
    useAllCaps: companyConfiguration?.allCapsInOrderPage === true,
    quickbooksDesktopEnabled: !isNil(companyData?.quickbooksDesktopCompanyId),
    purchasedProductOfferings: companyData?.purchasedProductOfferings,
    defaultNonCompanyDriverSettlementMode:
      companyData?.defaultNonCompanyDriverSettlementMode,
    refetchMeData,
  };
};

export type Me = ReturnType<typeof useMe>;
export default useMe;
