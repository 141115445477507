import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Controller,
  type SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import useMe from '../../../../common/react-hooks/use-me';
import {
  OrganizationType,
  useBusinessDivisionsQuery,
  useCreateOrganizationMutation,
  useOrganizationForFrontendHookQuery,
} from '../../../../generated/graphql';
import DefaultAddressAutocomplete from '../../components/default-address-autocomplete';
import DefaultContactPersonAutocomplete from '../../components/default-contact-person-autocomplete';
import OrganizationNotes from '../../components/organization-notes';
import { useOrganizationFormContext } from '../../contexts/organization-form-context';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';
import ServiceLevelConfigCard from './service-level-config-card';
import EdiConfigCard from './edi-config-card';
import BillingConfigCard from './billing-config-card';
import VehicleTypesConfigCard from './vehicle-types-config-card';
import ReferenceNumberLabelsConfigCard from './reference-number-labels-config-card';

const GeneralTab = ({
  mode,
  setSelectedOrganizationTypes,
  onSubmit,
}: {
  readonly mode: OrganizationPageMode;
  readonly setSelectedOrganizationTypes: (types: OrganizationType[]) => void;
  readonly onSubmit: () => void;
}) => {
  const { control } = useFormContext<OrganizationFormValues>();
  const { isFieldRequired, isFieldVisible } = useOrganizationFormContext();
  const { userUuid, loading: meLoading } = useMe();
  const uuid = useWatch({ control, name: 'uuid' });

  const showBusinessDivision = isFieldVisible('defaultBusinessDivisionUuid');
  const showIdentifier = isFieldVisible('invoiceOrOrderIdentifier');
  const showDefaultAddress = isFieldVisible('defaultAddressUuid');
  const showDefaultContactPerson = isFieldVisible('defaultContactPersonUuid');
  const showServiceLevels = isFieldVisible('serviceUuids');
  const showVehicleTypes = isFieldVisible('vehicleTypeIds');
  const showReferenceNumberLabels = isFieldVisible('referenceNumberLabels');

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    skip: !showBusinessDivision,
  });
  const businessDivisions =
    businessDivisionsData?.businessDivisions?.businessDivisions ?? [];
  const styles = useStyles();

  const { data: organizationData, loading: organizationLoading } =
    useOrganizationForFrontendHookQuery({
      skip: isNil(uuid) || mode === OrganizationPageMode.CREATE,
      variables: {
        input: { uuid },
      },
    });

  return (
    <Stack width="100%" gap={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Button variant="contained" onClick={onSubmit}>
          Create
        </Button>
      </Stack>
      <Stack direction="row" gap={2}>
        <Card
          sx={{
            flex: 3,
            minHeight: '200px',
            ...styles.card,
          }}
        >
          <CardContent sx={styles.cardContent}>
            <Stack gap={2}>
              <Typography variant="h6">General</Typography>
              <Stack direction="row" gap={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      required={isFieldRequired('name')}
                      sx={{ flex: 1 }}
                    >
                      <FormLabel>Name</FormLabel>
                      <TextField
                        {...field}
                        sx={{ width: '80%' }}
                        placeholder="Name"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="types"
                  control={control}
                  defaultValue={[]}
                  render={({ field: { value = [], onChange } }) => (
                    <FormControl
                      required={isFieldRequired('types')}
                      sx={{ flex: 1 }}
                    >
                      <FormLabel>Account type</FormLabel>
                      <FormGroup row sx={{ gap: 5 }}>
                        {Object.values(OrganizationType).map((enumValue) => (
                          <FormControlLabel
                            key={enumValue}
                            control={
                              <Checkbox
                                checked={value.includes(enumValue)}
                                onChange={(e) => {
                                  const newValue = e.target.checked
                                    ? [...value, enumValue]
                                    : value.filter((v) => v !== enumValue);
                                  onChange(newValue);
                                  setSelectedOrganizationTypes(newValue);
                                }}
                              />
                            }
                            label={enumValue}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      required={isFieldRequired('email')}
                      sx={{ flex: 1 }}
                    >
                      <FormLabel>Email</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Email"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="referenceNumber"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      required={isFieldRequired('referenceNumber')}
                      sx={{ flex: 1 }}
                    >
                      <FormLabel>Account ID</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Account ID"
                        variant="outlined"
                      />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                {showBusinessDivision && (
                  <Controller
                    name="defaultBusinessDivisionUuid"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ flex: 1 }}>
                        <FormLabel>Business Division</FormLabel>
                        <Select
                          {...field}
                          id="business-division-select"
                          disabled={mode === OrganizationPageMode.VIEW}
                          value={field.value ?? 'None'}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {businessDivisions?.map((businessDivision) => (
                            <MenuItem
                              key={businessDivision.uuid}
                              value={businessDivision.uuid}
                            >
                              {businessDivision.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {showIdentifier && (
                  <Controller
                    name="invoiceOrOrderIdentifier"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        required={isFieldRequired('invoiceOrOrderIdentifier')}
                        sx={{ flex: 1 }}
                      >
                        <FormLabel>Invoice/Order Identifier</FormLabel>
                        <TextField
                          {...field}
                          sx={{ flex: 1 }}
                          placeholder="Invoice/Order Identifier"
                          variant="outlined"
                        />
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
              <Stack direction="row" gap={2}>
                {showDefaultContactPerson && (
                  <DefaultContactPersonAutocomplete
                    control={control}
                    organizationContactPersons={
                      organizationData?.organization?.contactPersons ?? []
                    }
                  />
                )}
                {showDefaultAddress ? (
                  <DefaultAddressAutocomplete
                    control={control}
                    organizationAddresses={
                      organizationData?.organization?.addresses ?? []
                    }
                  />
                ) : (
                  // add a flex so the contact person autocomplete doesn't fill
                  <Stack flex={1} />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        {mode === OrganizationPageMode.EDIT && !isNil(uuid) && !meLoading && (
          <Card sx={{ flex: 2, ...styles.card }}>
            <CardContent sx={styles.cardContent}>
              <Stack gap={2}>
                <Typography variant="h6">Notes</Typography>
                <OrganizationNotes
                  organizationUuid={uuid}
                  userUuid={userUuid}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
      {showServiceLevels && <ServiceLevelConfigCard mode={mode} />}
      <EdiConfigCard mode={mode} />
      <BillingConfigCard mode={mode} />
      {showVehicleTypes && <VehicleTypesConfigCard mode={mode} />}
      {showReferenceNumberLabels && (
        <ReferenceNumberLabelsConfigCard mode={mode} />
      )}
    </Stack>
  );
};

export default GeneralTab;
