import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { isNil, uniqBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { Sidebar } from '../../../common/components/sidebar';
import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY } from '../../../common/local-storage/keys';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import CustomerPortalCompanySelectModal from './customer-portal-order-form/customer-portal-company-select-modal';
import { CustomerPortalUserMenu } from './customer-portal-user-menu';
import useCustomerPortalDrawerItems from './use-customer-portal-drawer-items';

const CustomerPortalSidebar = React.memo(() => {
  const { drawerItems } = useCustomerPortalDrawerItems();
  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );
  const [showCompanySelectModal, setShowCompanySelectModal] = useState(false);
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const navigate = useNavigate();

  const distinctCompanies = useMemo(() => {
    if (isNil(thirdPartyUser)) {
      return [];
    }
    const companies = thirdPartyUser.contacts.map((c) => c.company);
    return uniqBy(companies, ({ uuid }) => uuid);
  }, [thirdPartyUser]);

  const handleAddOrder = () => {
    if (distinctCompanies.length === 1) {
      const firstContactUuid = thirdPartyUser?.contacts[0]?.uuid;
      if (isNilOrEmptyString(firstContactUuid)) {
        return;
      }
      navigate(`/customer-portal/orders/add?contactUuid=${firstContactUuid}`);
    } else {
      setShowCompanySelectModal(true);
    }
  };

  const drawerActions = ffCustomerPortalOrderEntry && (
    <>
      {showCompanySelectModal && (
        <CustomerPortalCompanySelectModal
          onClose={() => {
            setShowCompanySelectModal(false);
          }}
        />
      )}
      <Button
        startIcon={<Add />}
        sx={{
          color: '#fff',
          whiteSpace: 'nowrap',
          backgroundColor: (theme) => theme.palette.airfreightBlue[70],
          '&:hover': {
            backgroundColor: (theme) => theme.palette.airfreightBlue[60],
          },
        }}
        onClick={handleAddOrder}
      >
        Add Order
      </Button>
    </>
  );

  return (
    <Sidebar
      drawerItems={drawerItems}
      actionItems={drawerActions}
      sidebarOpenLocalStorageKey={CUSTOMER_PORTAL_SIDEBAR_OPEN_KEY}
      userMenu={<CustomerPortalUserMenu />}
    />
  );
});

export default CustomerPortalSidebar;
