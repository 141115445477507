import { StatsigProvider } from '@statsig/react-bindings';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useMe from '../common/react-hooks/use-me';
import useGlobalStore from '../layouts/dashboard/global-store';
import useFeatureFlagPolling from './hooks/use-feature-flag-pollilng';
import useStatsigClient, {
  type StatsigUserDetails,
} from './hooks/use-statsig-client';
import useMeAsThirdPartyUser from '../common/react-hooks/use-me-as-third-party-user';

type AsyncStatsigProviderProps = {
  readonly children: React.ReactNode;
  readonly isThirdPartyUser?: true;
};
const AsyncStatsigProvider = ({
  children,
  isThirdPartyUser,
}: AsyncStatsigProviderProps) => {
  const [setStatsigLoading] = useGlobalStore(
    (state) => [state.setStatsigLoading],
    shallow,
  );
  const { userUuid, email, companyUuid } = useMe({
    skip: isThirdPartyUser,
  });

  const { uuid: thirdPartyUserUuid, email: thirdPartyUserEmail } =
    useMeAsThirdPartyUser({ skip: !isThirdPartyUser });

  const userDetails: StatsigUserDetails = isThirdPartyUser
    ? {
        userUuid: thirdPartyUserUuid,
        userEmail: thirdPartyUserEmail,
        companyUuid: undefined,
      }
    : {
        userUuid,
        userEmail: email,
        companyUuid,
      };

  const client = useStatsigClient(userDetails);
  useFeatureFlagPolling(client, userDetails);

  useEffect(() => {
    if (isNil(client)) {
      return;
    }
    const initializeStatsig = async () => {
      setStatsigLoading(true);
      try {
        await client.initializeAsync();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing statsig', error);
      } finally {
        setStatsigLoading(false);
      }
    };
    initializeStatsig();
  }, [client, setStatsigLoading]);

  if (isNil(client)) {
    return children;
  }
  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};

export default AsyncStatsigProvider;
