import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { FormProvider, type UseFormReturn } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { type ThirdPartyUserCompany } from '../types';
import CustomerPortalOrderForm from './customer-portal-order-form/customer-portal-order-form';
import { type CustomerPortalOrderFormValues } from './customer-portal-order-form/forms/types';
import { useCreateCustomerPortalOrderForm } from './customer-portal-order-form/hooks/use-create-customer-portal-order-form';

type CustomerPortalAddOrderPageWithContactUuidProps = {
  readonly contactUuid: string;
  readonly setContactUuid: (contactUuid: string) => void;
  readonly form: UseFormReturn<CustomerPortalOrderFormValues>;
};

const CustomerPortalAddOrderPageWithContactUuid = ({
  contactUuid,
  setContactUuid: rawSetContactUuid,
  form,
}: CustomerPortalAddOrderPageWithContactUuidProps) => {
  const { thirdPartyUser } = useMeAsThirdPartyUser();

  // We can derive the company from the selected contact UUID (so no need to
  // store it in the form state)
  const company: ThirdPartyUserCompany | null =
    thirdPartyUser?.contacts.find((c) => c.uuid === contactUuid)?.company ??
    null;

  const setContactUuid = (newContactUuid: string) => {
    const newCompanyUuid = thirdPartyUser?.contacts.find(
      (c) => c.uuid === newContactUuid,
    )?.company.uuid;
    if (newCompanyUuid !== company?.uuid) {
      // The form may have company-specific values (e.g. service level or
      // terminal UUIDs) that we need to reset when switching companies.
      form.reset();
    }
    rawSetContactUuid(newContactUuid);
  };

  if (isNilOrEmptyString(contactUuid) || isNil(company?.configuration)) {
    return <Navigate to="/customer-portal/orders" />;
  }

  return (
    <FormProvider {...form}>
      <CustomerPortalOrderForm
        company={company}
        contactUuid={contactUuid}
        setContactUuid={setContactUuid}
      />
    </FormProvider>
  );
};

const CustomerPortalAddOrderPage = () => {
  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const navigationContactUuid: string | null =
    typeof location.state?.contactUuid === 'string'
      ? location.state?.contactUuid
      : null;

  // State management is tricky here:
  // - We want to load the contact UUID from the navigation state
  // - We want to update the contact UUID in the form if navigation changes
  //   (e.g. user navigates to the same page with a different contact)
  // - We want to minimize duplicate states / useEffects (e.g. not also putting the contact
  //   UUID in the form state)
  const [contactUuid, setContactUuid] = useState<string | null>(
    navigationContactUuid,
  );

  const form = useCreateCustomerPortalOrderForm();

  // If the navigation state changes, read the new contact UUID
  useEffect(() => {
    if (isNilOrEmptyString(navigationContactUuid)) {
      return;
    }
    setContactUuid(navigationContactUuid);
    // The form may have company-specific values (e.g. service level or
    // terminal UUIDs) that we need to reset when switching companies.
    form.reset();
  }, [navigationContactUuid, form]);

  const { loading: thirdPartyUserLoading } = useMeAsThirdPartyUser();
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );
  // Also wait for third party user to load so we can check its feature flags
  if (statsigLoading || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  if (!ffCustomerPortalOrderEntry || isNilOrEmptyString(contactUuid)) {
    return <Navigate to="/customer-portal/orders" />;
  }

  return (
    <CustomerPortalAddOrderPageWithContactUuid
      contactUuid={contactUuid}
      setContactUuid={setContactUuid}
      form={form}
    />
  );
};

export default CustomerPortalAddOrderPage;
