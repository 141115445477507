import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  Stack,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import { SettlementBillLineItemsRow } from './settlement-bill-line-items-row';
import { CHECKBOX_CELL_WIDTH, CompactRow } from './common';
import { useGetSettlementBillLineItems } from '../hooks/use-get-settlement-bill-line-items';
import { useState } from 'react';
import PalletButtonGroup from '../../../pallet-ui/button-group/pallet-button-group';
import { Tabs } from '../enums';
import { values } from 'lodash';
import { isNil } from 'lodash';
import PalletDateRangePicker from '../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import { OrderDialog } from '../../orders/components/order-dialog';
import DriverFilterButton from '../../../common/components/driver-filter-button';
import { NetworkStatus } from '@apollo/client';

const ROW_PER_PAGE_OPTIONS = [25, 50, 100];

// This is the number of columns in the table and should be updated if the number of columns changes
const COLUMNS_COUNT = 10;

export const SettlementBillLineItemsTable = () => {
  const {
    refresh,
    selectedSettlementBillLineItemIds,
    setSelectedSettlementBillLineItemIds,
    driverOptions,
    rangeStart,
    rangeEnd,
    tab,
    handleTabChange,
    totalCount,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    handleDriverOptionsChange,
    handleRangeChange,
    loadingSettlementBillLineItems,
    settlementBillLineItems,
  } = useGetSettlementBillLineItems();

  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);

  const onSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setSelectedSettlementBillLineItemIds(
        new Set(settlementBillLineItems.map((item) => item.id)),
      );
    } else {
      setSelectedSettlementBillLineItemIds(new Set());
    }
  };

  const onCheckboxChange = (settlementBillLineItemId: string) => {
    setSelectedSettlementBillLineItemIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(settlementBillLineItemId)) {
        newSet.delete(settlementBillLineItemId);
      } else {
        newSet.add(settlementBillLineItemId);
      }
      return newSet;
    });
  };

  const dateLabel = tab === Tabs.UNSETTLED ? 'Settlement date' : 'Completed at';

  return (
    <Stack display="flex" flexDirection="column" height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        padding={0.5}
        alignItems="center"
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <PalletButtonGroup
            size="small"
            options={values(Tabs).map((tab) => ({ label: tab, value: tab }))}
            value={tab}
            onChange={handleTabChange}
          />
          <PalletDateRangePicker
            isClearable
            value={[rangeStart, rangeEnd]}
            label={dateLabel}
            isRequired={false}
            sx={{ width: '250px' }}
            onAccept={([rangeStart, rangeEnd]) => {
              handleRangeChange([rangeStart, rangeEnd]);
            }}
          />
          <DriverFilterButton
            selectedOptionsMultiselect={driverOptions}
            handleChangeMultiselect={handleDriverOptionsChange}
            prefixText="Driver"
          />
        </Stack>
        <TablePagination
          rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
          labelRowsPerPage="Show"
          component="div"
          count={totalCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={async (_, newPage) => {
            await handlePageChange({ newPage, forward: newPage > page });
          }}
          onRowsPerPageChange={async (event) => {
            const pageSize = Number(event.target.value);
            await handleRowsPerPageChange(pageSize);
          }}
        />
      </Stack>
      <TableContainer sx={{ overflowY: 'auto', flex: 1 }}>
        <Table
          stickyHeader
          aria-label="stop-driver-maps-table"
          size="small"
          sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: '5px 5px',
            },
          }}
        >
          <colgroup>
            <col style={{ width: '1%' }} />
            {Array.from({ length: COLUMNS_COUNT - 1 }).map((_, index) => (
              <col
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ width: `${99 / COLUMNS_COUNT}%` }}
              />
            ))}
          </colgroup>
          <TableHead>
            <CompactRow>
              {tab !== Tabs.SETTLED && (
                <TableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
                  <Checkbox
                    checked={
                      selectedSettlementBillLineItemIds.size ===
                        settlementBillLineItems.length &&
                      settlementBillLineItems.length > 0
                    }
                    indeterminate={
                      selectedSettlementBillLineItemIds.size > 0 &&
                      selectedSettlementBillLineItemIds.size <
                        settlementBillLineItems.length
                    }
                    onChange={onSelectAllCheckboxChange}
                  />
                </TableCell>
              )}
              <TableCell>
                {/* TODO: add sort functionality when flat view is implemented BKO-1486 */}
                <TableSortLabel>{dateLabel}</TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel>Driver/Agent</TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell sx={{ minWidth: '25px' }}>Stop type</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Ref #s</TableCell>
              <TableCell>Total Payout</TableCell>
              <TableCell />
            </CompactRow>
          </TableHead>
          {loadingSettlementBillLineItems ? (
            <CircularProgress />
          ) : (
            <TableBody>
              {settlementBillLineItems.map((settlementBillLineItem) => (
                <SettlementBillLineItemsRow
                  key={settlementBillLineItem.id}
                  settlementBillLineItem={settlementBillLineItem}
                  isChecked={selectedSettlementBillLineItemIds.has(
                    settlementBillLineItem.id,
                  )}
                  setOpenedOrderUuid={setOpenedOrderUuid}
                  tab={tab}
                  onCheckboxChange={onCheckboxChange}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid ?? null}
        onClose={async () => {
          setOpenedOrderUuid(null);
          await refresh();
        }}
      />
    </Stack>
  );
};

export default SettlementBillLineItemsTable;
