import { isNil } from 'lodash';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { EnvironmentVariables } from '../../../../../environment-variables';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { type CustomerPortalOrderFormValues } from '../forms/types';
import { getCreateOrderV3Request } from '../forms/utils';

/**
 * Hook for saving orders in the customer portal.
 *
 * This expects to be called with third-party user auth,
 * so it should only be used in the customer portal.
 *
 * This also expects to be called within the OrderFormValues RHF context.
 */
const useSaveOrderCustomerPortal = ({
  companyUuid,
  contactUuid,
}: {
  companyUuid: string;
  contactUuid: string;
}) => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const saveOrder = useCallback(
    async (orderValues: CustomerPortalOrderFormValues) => {
      const createOrderV3Request = getCreateOrderV3Request({
        orderValues,
        clientId: companyUuid,
        customerId: contactUuid,
      });

      try {
        const res = await fetch(
          `${EnvironmentVariables.VITE_BACKEND_URL}/v3/orders`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(createOrderV3Request),
          },
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const data = await res.json();
        if (res.status !== 201 || !isNil(data.error)) {
          throw new Error(
            (data.error as string | undefined) ?? 'Error saving order',
          );
        }

        setSuccessMessage('Order saved');
        setShowSuccessMessage(true);
      } catch (error) {
        setErrorMessage(
          error instanceof Error ? error.message : 'Error saving order',
        );
        setShowErrorMessage(true);
        throw error;
      }
    },
    [
      companyUuid,
      contactUuid,
      setSuccessMessage,
      setShowSuccessMessage,
      setErrorMessage,
      setShowErrorMessage,
    ],
  );

  return {
    /**
     * Saves the order from the current form values.
     *
     * @param orderValues - The current form values from RHF's handleSubmit
     * Use handleSubmit's success callback to get the order values so that
     * Zod transformations (e.g. trimming strings) are applied.
     *
     * @throws an error if the request fails.
     */
    saveOrder,
  };
};

export { useSaveOrderCustomerPortal };
