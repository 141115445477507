import { FormControl, FormLabel, TextField } from '@mui/material';
import { type Control, Controller } from 'react-hook-form';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useState } from 'react';
import { type OrganizationFormValues } from '../form/types';
import { useOrganizationData } from '../hooks/use-organization-data';
import { type AddressForOrganizationFragment } from '../../../generated/graphql';

type AddressOption = {
  label: string;
  uuid: string;
};

type DefaultAddressAutocompleteProps = {
  readonly control: Control<OrganizationFormValues>;
  readonly organizationAddresses: AddressForOrganizationFragment[];
};

const DefaultAddressAutocomplete = ({
  control,
  organizationAddresses,
}: DefaultAddressAutocompleteProps) => {
  const [defaultAddressInputValue, setDefaultAddressInputValue] = useState('');

  const addressOptions: AddressOption[] =
    organizationAddresses?.map((address) => ({
      label: `${address.line1}, ${address.city}`,
      uuid: address.uuid,
    })) ?? [];

  return (
    <Controller
      name="defaultAddressUuid"
      control={control}
      render={({ field }) => (
        <FormControl size="small" sx={{ flex: 1 }}>
          <FormLabel>Default address</FormLabel>
          <AutocompleteFuzzy
            inputValue={defaultAddressInputValue ?? ''}
            isOptionEqualToValue={(option, value) =>
              option.uuid === value.uuid && option.label === value.label
            }
            renderInput={(params) => <TextField {...params} size="medium" />}
            options={addressOptions}
            matchSortOptions={{ keys: ['label'] }}
            sx={{ backgroundColor: 'white' }}
            value={
              addressOptions.find((option) => option.uuid === field.value) ??
              null
            }
            onChange={(e, newValue) => {
              field.onChange(newValue?.uuid ?? null);
            }}
            onInputChange={(e, newInputValue) => {
              setDefaultAddressInputValue(newInputValue);
            }}
          />
        </FormControl>
      )}
    />
  );
};

export default DefaultAddressAutocomplete;
