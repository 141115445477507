import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAvailableTariffTypes } from '../../../../common/react-hooks/use-available-tariff-types';
import { useTariffChains } from '../../../../common/react-hooks/use-tariff-chains';
import TariffChainSelect from '../../components/tariff-chain-select';
import { useOrganizationFormContext } from '../../contexts/organization-form-context';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';

const BillingConfigCard = ({
  mode,
}: {
  readonly mode: OrganizationPageMode;
}) => {
  const styles = useStyles();
  const { control } = useFormContext<OrganizationFormValues>();
  const { isFieldVisible } = useOrganizationFormContext();

  const {
    ordinaryTariffChains,
    lineHaulTariffChains,
    transferTariffChains,
    pointToPointTariffChains,
  } = useTariffChains();

  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const tariffChainDropdowns: ReactNode[] = [];

  if (
    ordinaryTariffGroupAvailable &&
    !isNil(ordinaryTariffChains) &&
    isFieldVisible('defaultOrdinaryTariffChainId')
  ) {
    tariffChainDropdowns.push(
      <TariffChainSelect
        label="Default ordinary tariff chain"
        name="defaultOrdinaryTariffChainId"
        tariffChains={ordinaryTariffChains}
        mode={mode}
        control={control}
      />,
    );
  }

  if (
    lineHaulTariffGroupAvailable &&
    !isNil(lineHaulTariffChains) &&
    isFieldVisible('defaultLineHaulTariffChainId')
  ) {
    tariffChainDropdowns.push(
      <TariffChainSelect
        label="Default line-haul tariff chain"
        name="defaultLineHaulTariffChainId"
        tariffChains={lineHaulTariffChains}
        mode={mode}
        control={control}
      />,
    );
  }

  if (
    transferTariffGroupAvailable &&
    !isNil(transferTariffChains) &&
    isFieldVisible('defaultTransferTariffChainId')
  ) {
    tariffChainDropdowns.push(
      <TariffChainSelect
        label="Default transfer tariff chain"
        name="defaultTransferTariffChainId"
        tariffChains={transferTariffChains}
        mode={mode}
        control={control}
      />,
    );
  }

  if (
    pointToPointTariffGroupAvailable &&
    !isNil(pointToPointTariffChains) &&
    isFieldVisible('defaultPointToPointTariffChainId')
  ) {
    tariffChainDropdowns.push(
      <TariffChainSelect
        label="Default point-to-point tariff chain"
        name="defaultPointToPointTariffChainId"
        tariffChains={pointToPointTariffChains}
        mode={mode}
        control={control}
      />,
    );
  }

  if (
    !isFieldVisible('defaultFuelSurcharge') &&
    !isFieldVisible('defaultDimFactor')
  ) {
    return null;
  }

  return (
    <Card sx={{ ...styles.card }}>
      <CardContent sx={styles.cardContent}>
        <Stack gap={2}>
          <Typography variant="h6">Billing</Typography>
          <Stack direction="row" gap={2}>
            {isFieldVisible('defaultFuelSurcharge') && (
              <Controller
                name="defaultFuelSurcharge"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Default fuel surcharge</FormLabel>
                    <TextField
                      {...field}
                      placeholder="Default fuel surcharge"
                      value={field.value ?? ''}
                      variant="outlined"
                      disabled={mode === OrganizationPageMode.VIEW}
                    />
                  </FormControl>
                )}
              />
            )}
            {isFieldVisible('defaultDimFactor') && (
              <Controller
                name="defaultDimFactor"
                control={control}
                render={({ field }) => (
                  <FormControl sx={{ flex: 1 }}>
                    <FormLabel>Default dim factor</FormLabel>
                    <TextField
                      {...field}
                      value={field.value ?? ''}
                      placeholder="Default dim factor"
                      variant="outlined"
                      disabled={mode === OrganizationPageMode.VIEW}
                    />
                  </FormControl>
                )}
              />
            )}
          </Stack>
          <Stack direction="row" gap={2}>
            {isFieldVisible('showEdiReferenceNumbersOnOrderPage') && (
              <Controller
                name="showEdiReferenceNumbersOnOrderPage"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value ?? false}
                        disabled={mode === OrganizationPageMode.VIEW}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="Show EDI reference numbers on order page"
                  />
                )}
              />
            )}
          </Stack>
          <Stack direction="row" gap={2}>
            {isFieldVisible('defaultUseTariff') && (
              <Controller
                name="defaultUseTariff"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value ?? false}
                        disabled={mode === OrganizationPageMode.VIEW}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="Default to tariff"
                  />
                )}
              />
            )}
          </Stack>
          <Stack direction="row" gap={2}>
            {isFieldVisible('isPrepaidOnly') && (
              <Controller
                name="isPrepaidOnly"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value ?? false}
                        disabled={mode === OrganizationPageMode.VIEW}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    }
                    label="Prepaid only"
                  />
                )}
              />
            )}
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight="medium">Tariff chains</Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
              }}
            >
              {tariffChainDropdowns.map((dropdown) => (
                <Box key={null} sx={{ width: 'calc(50% - 8px)' }}>
                  {dropdown}
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BillingConfigCard;
