import { isNil } from 'lodash';
import { createContext, useContext } from 'react';
import { type FieldPath } from 'react-hook-form';
import { type OrganizationFormValues } from '../form/types';

export type OrganizationFormContextValue = {
  isFieldVisible: (key: FieldPath<OrganizationFormValues>) => boolean;
  isFieldRequired: (key: FieldPath<OrganizationFormValues>) => boolean;
};

const context = createContext<OrganizationFormContextValue | undefined>(
  undefined,
);

export const useOrganizationFormContext = (): OrganizationFormContextValue => {
  const contextValue = useContext(context);
  if (isNil(contextValue)) {
    throw new Error(
      'useOrganizationFormContext must be used within an OrganizationFormContextProvider',
    );
  }
  return contextValue;
};

export const OrganizationFormContextProvider = context.Provider;
