import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';
import CustomerVehicleTypesMultiSelect from '../../components/customer-vehicle-types-multi-select';
import { useVehicleTypesMinimalQuery } from '../../../../generated/graphql';

const VehicleTypesConfigCard = ({
  mode,
}: {
  readonly mode: OrganizationPageMode;
}) => {
  const styles = useStyles();
  const { control } = useFormContext<OrganizationFormValues>();

  const { data: vehicleTypesData, loading } = useVehicleTypesMinimalQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Card sx={{ ...styles.card }}>
      <CardContent sx={styles.cardContent}>
        <Stack gap={2}>
          <Typography variant="h6">Vehicle types</Typography>
          <Stack direction="row" gap={2}>
            <Controller
              name="vehicleTypeIds"
              control={control}
              render={({ field }) => (
                <CustomerVehicleTypesMultiSelect
                  {...field}
                  control={control}
                  selectedVehicleTypeIds={field.value}
                  disabled={mode === OrganizationPageMode.VIEW}
                />
              )}
            />
            <Controller
              name="useCustomVehicleTypes"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ flex: 1 }}
                  control={
                    <Checkbox
                      checked={field.value ?? false}
                      disabled={mode === OrganizationPageMode.VIEW}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Customize available vehicle types"
                />
              )}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default VehicleTypesConfigCard;
