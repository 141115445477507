import { z } from 'zod';
import { OrganizationType } from '../../../generated/graphql';
import { zOMITTED } from '../../orders/components/order-form/forms/zod-utils';

// This is a separate function since we can use
// the return type as the type for the base schema
const getBaseOrganizationSchemaWithoutRefinements = () => {
  const schema = z.object({
    uuid: z.string().uuid(),
    name: z.string().min(1, 'Name is required'),
    referenceNumber: z.string().min(1, 'Reference number is required'),
    types: z.array(z.nativeEnum(OrganizationType)),
    email: z.string().email('Invalid email address'),
    defaultAddressUuid: z.string().uuid().nullable(),
    defaultBusinessDivisionUuid: z.string().uuid().nullable(),
    invoiceOrOrderIdentifier: z.string().nullable(),
    defaultContactPersonUuid: z.string().uuid().nullable(),
    defaultServiceUuid: z.string().uuid().nullable(),
    defaultInboundEdiServiceUuid: z.string().uuid().nullable(),
    defaultOutboundEdiServiceUuid: z.string().uuid().nullable(),
    defaultInboundOutboundEdiServiceUuid: z.string().uuid().nullable(),
    serviceUuids: z.array(z.string().uuid()).nullable(),
    isaId: z.string().nullable(),
    ediApplicationId: z.string().nullable(),
    stediPartnerId: z.string().nullable(),
    ediContactCode: z.string().nullable(),
    showEdiReferenceNumbersOnOrderPage: z.boolean().nullable(),
    defaultPointToPointTariffChainId: z.string().uuid().nullable(),
    defaultFuelSurcharge: z.number().nullable(),
    defaultDimFactor: z.number().nullable(),
    defaultLineHaulTariffChainId: z.string().uuid().nullable(),
    defaultOrdinaryTariffChainId: z.string().uuid().nullable(),
    defaultTransferTariffChainId: z.string().uuid().nullable(),
    defaultUseTariff: z.boolean().nullable(),
    isPrepaidOnly: z.boolean().nullable(),
    vehicleTypeIds: z.array(z.string()).nullable(),
    useCustomVehicleTypes: z.boolean().nullable(),
    referenceNumberLabels: z
      .array(
        z.object({
          name: z.string(),
        }),
      )
      .nullable(),
  });
  return schema;
};

const getCustomerSchemaWithoutRefinements = () => {
  const baseSchema = getBaseOrganizationSchemaWithoutRefinements();
  return baseSchema;
};

const getAgentSchemaWithoutRefinements = () => {
  const baseSchema = getBaseOrganizationSchemaWithoutRefinements();
  const agentSchema = baseSchema.extend({
    defaultBusinessDivisionUuid: zOMITTED,
    defaultAddressUuid: zOMITTED,
    invoiceOrOrderIdentifier: zOMITTED,
    defaultServiceUuid: zOMITTED,
    defaultInboundEdiServiceUuid: zOMITTED,
    defaultOutboundEdiServiceUuid: zOMITTED,
    defaultInboundOutboundEdiServiceUuid: zOMITTED,
    serviceUuids: zOMITTED,
    showEdiReferenceNumbersOnOrderPage: zOMITTED,
    defaultPointToPointTariffChainId: zOMITTED,
    defaultFuelSurcharge: zOMITTED,
    defaultDimFactor: zOMITTED,
    defaultLineHaulTariffChainId: zOMITTED,
    defaultOrdinaryTariffChainId: zOMITTED,
    defaultTransferTariffChainId: zOMITTED,
    defaultUseTariff: zOMITTED,
    isPrepaidOnly: zOMITTED,
    vehicleTypeIds: zOMITTED,
    useCustomVehicleTypes: zOMITTED,
    referenceNumberLabels: zOMITTED,
    // agents can have a default contact person
    // defaultContactPersonUuid: zOMITTED,
  });
  return agentSchema;
};

export const getOrganizationSchema = (
  organizationTypes: OrganizationType[],
) => {
  if (
    organizationTypes.includes(OrganizationType.Customer) &&
    organizationTypes.includes(OrganizationType.Agent)
  ) {
    return getBaseOrganizationSchemaWithoutRefinements();
  }
  if (organizationTypes.includes(OrganizationType.Agent)) {
    return getAgentSchemaWithoutRefinements();
  }
  return getCustomerSchemaWithoutRefinements();
};
