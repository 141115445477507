import { Button, Checkbox, Tooltip } from '@mui/material';
import { type SettlementBillLineItem } from '../types';
import { sentenceCase } from 'change-case';
import { CHECKBOX_CELL_WIDTH, CompactRow, SettlementTableCell } from './common';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { type SetStateAction, type Dispatch, useState } from 'react';
import { Tabs } from '../enums';

type SettlementBillLineItemRowProps = {
  readonly settlementBillLineItem: SettlementBillLineItem;
  readonly isChecked: boolean;
  readonly onCheckboxChange: (settlementBillLineItemId: string) => void;
  readonly setOpenedOrderUuid: Dispatch<SetStateAction<string | null>>;
  readonly tab: Tabs;
};

export const SettlementBillLineItemsRow = ({
  settlementBillLineItem,
  isChecked,
  onCheckboxChange,
  setOpenedOrderUuid,
  tab,
}: SettlementBillLineItemRowProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const { shipment, driver } = settlementBillLineItem;
  const order = shipment?.order;
  const stop = shipment?.legs[0]?.endStop;

  const formattedCompletedAt = isNil(stop?.completedAt)
    ? 'Incomplete'
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      dayjs(stop.completedAt).format('MM/DD/YY');
  const refNumbers = order?.refNumbers.join(', ');

  return (
    <CompactRow
      hover={isHovered}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
    >
      {tab !== Tabs.SETTLED && (
        <SettlementTableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
          <Checkbox
            checked={isChecked}
            onChange={() => {
              onCheckboxChange(settlementBillLineItem.id);
            }}
          />
        </SettlementTableCell>
      )}
      <SettlementTableCell>{formattedCompletedAt}</SettlementTableCell>
      <SettlementTableCell>{driver?.fullName}</SettlementTableCell>
      <SettlementTableCell>
        {sentenceCase(driver?.driverType ?? '-')}
      </SettlementTableCell>
      <SettlementTableCell>
        <Tooltip
          title={order?.billingPartyContact?.displayName}
          placement="top"
        >
          <span>{order?.billingPartyContact?.displayName}</span>
        </Tooltip>
      </SettlementTableCell>
      <SettlementTableCell sx={{ maxWidth: '25px' }}>
        {sentenceCase(stop?.stopType ?? '-')}
      </SettlementTableCell>
      <SettlementTableCell>
        <Tooltip
          title={order?.standardOrderFields?.shipperBillOfLadingNumber}
          placement="top"
        >
          <span>{order?.standardOrderFields?.shipperBillOfLadingNumber}</span>
        </Tooltip>
      </SettlementTableCell>
      <SettlementTableCell>{order?.name}</SettlementTableCell>
      <SettlementTableCell>
        <Tooltip title={refNumbers} placement="top">
          <span>{refNumbers}</span>
        </Tooltip>
      </SettlementTableCell>
      <SettlementTableCell>
        {currency(settlementBillLineItem.totalAmountUsdDollars ?? 0).format()}
      </SettlementTableCell>
      <SettlementTableCell>
        {!isNil(order?.uuid) && (
          <Button
            variant="contained"
            size="small"
            sx={{
              visibility: isHovered ? 'visible' : 'hidden',
            }}
            onClick={() => {
              setOpenedOrderUuid(order.uuid);
            }}
          >
            Open
          </Button>
        )}
      </SettlementTableCell>
    </CompactRow>
  );
};
