import { CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import {
  useEffect,
  useState,
  type FunctionComponent,
  type SyntheticEvent,
} from 'react';
import { PaddingTabPanel } from '../../../common/components/tab-panel';
import { type OrganizationType } from '../../../generated/graphql';
import theme from '../../../theme';
import {
  OrganizationPageMode,
  OrganizationPageTabs,
  type OrganizationPageProps,
} from '../enums';
import GeneralTab from '../organization-tabs/general/general-tab';
import { useLoadOrganizationForm } from './use-load-organization-form';
import { type SubmitHandler } from 'react-hook-form';
import { type OrganizationFormValues } from './types';

const OrganizationPageForm: FunctionComponent<
  OrganizationPageProps & {
    readonly setSelectedOrganizationTypes: (types: OrganizationType[]) => void;
    readonly onSubmit: () => void;
  }
> = ({ setSelectedOrganizationTypes, onSubmit, ...props }) => {
  const { initializeForm, loading: formLoading } = useLoadOrganizationForm();

  const [currentTab, setCurrentTab] = useState<OrganizationPageTabs>(
    OrganizationPageTabs.GENERAL,
  );

  const handleChangeTab = (
    event: SyntheticEvent,
    newValue: OrganizationPageTabs,
  ) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    void initializeForm(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, props.uuid]);

  const generalTab = (
    <GeneralTab
      mode={props.mode}
      setSelectedOrganizationTypes={setSelectedOrganizationTypes}
      onSubmit={onSubmit}
    />
  );

  if (formLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack
      width="100%"
      height="100%"
      flexDirection="column"
      p={1}
      bgcolor={theme.palette.concreteGrey[10]}
    >
      <Tabs
        value={currentTab}
        aria-label="add-contact-tabs"
        onChange={handleChangeTab}
      >
        <Tab
          // Leads to weird states if navigating away in the middle of creating a new contact or address
          label="General"
          value={OrganizationPageTabs.GENERAL}
        />
      </Tabs>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.GENERAL}
        selectedValue={currentTab}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {generalTab}
      </PaddingTabPanel>
    </Stack>
  );
};

export default OrganizationPageForm;
