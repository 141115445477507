import { isNil } from 'lodash';
import { useOrganizationForFrontendHookQuery } from '../../../generated/graphql';

export const useOrganizationData = (uuid: string | null) => {
  const { data, loading, error } = useOrganizationForFrontendHookQuery({
    variables: { input: { uuid: uuid ?? '' } },
    skip: isNil(uuid),
    fetchPolicy: 'cache-first',
  });

  return {
    addresses: data?.organization?.addresses,
    contactPersons: data?.organization?.contactPersons,
    types: data?.organization?.types,
    loading,
    error,
  };
};
