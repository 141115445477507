import { Typography, Stack, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import { SettlementBillLineItemsTable } from './components/settlement-bill-line-items-table';

enum SettlementTab {
  SETTLEMENT_BILL_LINE_ITEMS = 'settlement-bill-line-items',
  SETTLEMENT_BILLS = 'settlement-bills',
}

export const SettlementPage = () => {
  const [currentTab, setCurrentTab] = useState<SettlementTab>(
    SettlementTab.SETTLEMENT_BILL_LINE_ITEMS,
  );

  return (
    <Stack height="100%">
      <Tabs
        value={currentTab}
        onChange={(_, value: SettlementTab) => {
          setCurrentTab(value);
        }}
      >
        <Tab
          label="Unsettled Stops"
          value={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
        />
        <Tab label="Settlement Bills" value={SettlementTab.SETTLEMENT_BILLS} />
      </Tabs>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
      >
        <SettlementBillLineItemsTable />
      </TabPanel>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILLS}
      >
        <Typography>Settlement Bills</Typography>
      </TabPanel>
    </Stack>
  );
};
