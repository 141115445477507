import { exhaustive } from 'shared/switch';
import {
  FREIGHT_CHARGE_RATE_SKELETON_TEST_ID,
  FREIGHT_CHARGE_TYPE_SELECT_TEST_ID,
  FUEL_CHARGE_RATE_INPUT_TEST_ID,
  FUEL_CHARGE_TYPE_SELECT_TEST_ID,
  LINE_HAUL_FUEL_CHARGE_RATE_INPUT_TEST_ID,
  LINE_HAUL_FUEL_CHARGE_TYPE_SELECT_TEST_ID,
  ORDER_PAGE_ADD_CUSTOM_CHARGE_BUTTON_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_CITY_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_LINE_1_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_LINE_2_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_NAME_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_STATE_TEST_ID_PREFIX,
  ORDER_PAGE_ADDRESS_ZIP_TEST_ID_PREFIX,
  ORDER_PAGE_COMPLETED_STOP_CHIP_TEST_ID,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_NAME_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_QUANTITY_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_CUSTOM_CHARGE_RATE_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_MARK_AS_COMPLETE_MODAL_COMPLETED_AT_INPUT_TEST_ID,
  ORDER_PAGE_MARK_AS_COMPLETE_MODAL_SIGNEE_INPUT_TEST_ID,
  ORDER_PAGE_PACKAGE_HEIGHT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_PACKAGE_LENGTH_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_PACKAGE_QUANTITY_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_PACKAGE_WEIGHT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_PACKAGE_WIDTH_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_ADD_DESTINATION_BUTTON_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_CARD_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_MARK_AS_MENU_BUTTON_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_MARK_AS_MENU_MARK_COMPLETED_OPTION_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_MARK_COMPLETED_BUTTON_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_POD_SIGNEE_NAME_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_SERVICE_DATE_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_SERVICE_DATE_TEST_ID_PREFIX,
  ORDER_PAGE_STOP_TYPE_RADIO_BUTTON_TEST_ID_PREFIX,
  ORDER_PAGE_TERMINAL_SELECT_INPUT_TEST_ID_PREFIX,
  ORDER_PAGE_TERMINAL_SELECT_TEST_ID_PREFIX,
} from './constants';
import { INBOUND_STOP_IDX } from './src/domains/orders/components/order-form/components/constants';
import {
  InboundMethod,
  OutboundMethod,
  StopType,
  TestOrderStopType,
} from './src/generated/graphql';

function getStopIdxPrefix({ stopIdx }: { stopIdx: number }) {
  return stopIdx === INBOUND_STOP_IDX ? 'INBOUND' : 'OUTBOUND';
}

export function getStopTerminalSelectTestId({ stopIdx }: { stopIdx: number }) {
  return `${getStopIdxPrefix({
    stopIdx,
  })}-${ORDER_PAGE_TERMINAL_SELECT_TEST_ID_PREFIX}`;
}

export function getStopCardTestId({ stopIdx }: { stopIdx: number }) {
  return `${getStopIdxPrefix({
    stopIdx,
  })}-${ORDER_PAGE_STOP_CARD_TEST_ID_PREFIX}`;
}

export function getStopAddDestinationTestId({ stopIdx }: { stopIdx: number }) {
  return `${getStopIdxPrefix({
    stopIdx,
  })}-${ORDER_PAGE_STOP_ADD_DESTINATION_BUTTON_TEST_ID_PREFIX}`;
}

export function getStopRecoveryLocationTestIds({
  stopIdx,
}: {
  stopIdx: number;
}) {
  return {
    recoveryLocationSelectTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_TEST_ID_PREFIX}`,
    recoveryLocationSelectInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_AIRPORT_INFO_SELECT_INPUT_TEST_ID_PREFIX}`,
  };
}

export function getStopAddCustomChargeButtonTestId({
  stopIdx,
}: {
  stopIdx: number;
}) {
  return `${getStopIdxPrefix({
    stopIdx,
  })}-${ORDER_PAGE_ADD_CUSTOM_CHARGE_BUTTON_TEST_ID_PREFIX}`;
}

export function getStopCustomChargeTestIds({
  stopIdx,
  customChargeIdx,
}: {
  stopIdx: number;
  customChargeIdx: number;
}) {
  return {
    customChargeAccessorialSelectTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeAccessorialSelectInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_SELECT_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeAccessorialZoneSelectTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeAccessorialZoneSelectInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_ZONE_SELECT_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeAccessorialChargeGroupSelectTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeAccessorialChargeGroupSelectInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_ACCESSORIAL_CHARGE_GROUP_SELECT_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeRateInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_RATE_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeQuantityInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_QUANTITY_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
    customChargeNameInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_CUSTOM_CHARGE_NAME_INPUT_TEST_ID_PREFIX}-${customChargeIdx}`,
  };
}

export function getStopMarkAsTestIds({ stopIdx }: { stopIdx: number }) {
  return {
    stopMarkAsMenuButtonTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_MARK_AS_MENU_BUTTON_TEST_ID_PREFIX}`,
    stopMarkAsMenuMarkCompletedOptionTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_MARK_AS_MENU_MARK_COMPLETED_OPTION_TEST_ID_PREFIX}`,
    stopMarkCompletedDialogPodSigneeNameInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_POD_SIGNEE_NAME_INPUT_TEST_ID_PREFIX}`,
    stopMarkCompletedDialogMarkCompletedButtonTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_MARK_COMPLETED_DIALOG_MARK_COMPLETED_BUTTON_TEST_ID_PREFIX}`,
  };
}

export function getStopFreightChargeTestIds({ stopIdx }: { stopIdx: number }) {
  const prefix = getStopIdxPrefix({
    stopIdx,
  });
  return {
    freightChargeTypeSelectTestId: `${prefix}-${FREIGHT_CHARGE_TYPE_SELECT_TEST_ID}`,
    freightChargeRateSkeletonLoadingTestId: `${prefix}-${FREIGHT_CHARGE_RATE_SKELETON_TEST_ID}`,
  };
}

export function getOrderChargesFreightChargeTestIds() {
  return {
    freightChargeTypeSelectTestId: `ORDER_CHARGES-${FREIGHT_CHARGE_TYPE_SELECT_TEST_ID}`,
    freightChargeRateSkeletonLoadingTestId: `ORDER_CHARGES-${FREIGHT_CHARGE_RATE_SKELETON_TEST_ID}`,
  };
}

export function getOrderChargesFuelChargeTestIds() {
  return {
    fuelChargeTypeSelectTestId: `ORDER_CHARGES-${FUEL_CHARGE_TYPE_SELECT_TEST_ID}`,
    fuelChargeRateInputTestId: `ORDER_CHARGES-${FUEL_CHARGE_RATE_INPUT_TEST_ID}`,
  };
}

export function getStopFuelChargeTestIds({ stopIdx }: { stopIdx: number }) {
  const prefix = getStopIdxPrefix({
    stopIdx,
  });
  return {
    fuelChargeTypeSelectTestId: `${prefix}-${FUEL_CHARGE_TYPE_SELECT_TEST_ID}`,
    fuelChargeRateInputTestId: `${prefix}-${FUEL_CHARGE_RATE_INPUT_TEST_ID}`,
  };
}

export function getLineHaulFuelChargeTestIds() {
  return {
    lineHaulFuelChargeTypeSelectTestId: `${LINE_HAUL_FUEL_CHARGE_TYPE_SELECT_TEST_ID}`,
    lineHaulFuelChargeRateInputTestId: `${LINE_HAUL_FUEL_CHARGE_RATE_INPUT_TEST_ID}`,
  };
}

export function getStopServiceDateTestId({ stopIdx }: { stopIdx: number }) {
  return {
    serviceDateTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_SERVICE_DATE_TEST_ID_PREFIX}`,
    serviceDateInputTestId: `${getStopIdxPrefix({
      stopIdx,
    })}-${ORDER_PAGE_STOP_SERVICE_DATE_INPUT_TEST_ID_PREFIX}`,
  };
}

export function getPackageFieldsTestIds({ idx }: { idx: number }) {
  return {
    packageQuantityTestId: `${ORDER_PAGE_PACKAGE_QUANTITY_INPUT_TEST_ID_PREFIX}-${idx}`,
    packageWeightTestId: `${ORDER_PAGE_PACKAGE_WEIGHT_INPUT_TEST_ID_PREFIX}-${idx}`,
    packageLengthTestId: `${ORDER_PAGE_PACKAGE_LENGTH_INPUT_TEST_ID_PREFIX}-${idx}`,
    packageWidthTestId: `${ORDER_PAGE_PACKAGE_WIDTH_INPUT_TEST_ID_PREFIX}-${idx}`,
    packageHeightTestId: `${ORDER_PAGE_PACKAGE_HEIGHT_INPUT_TEST_ID_PREFIX}-${idx}`,
  };
}

export function getStopTerminalSelectInputTestId({
  stopIdx,
}: {
  stopIdx: number;
}) {
  return `${getStopIdxPrefix({
    stopIdx,
  })}-${ORDER_PAGE_TERMINAL_SELECT_INPUT_TEST_ID_PREFIX}`;
}

export function getStopAddressComponentsTestIds({
  stopIdx,
}: {
  stopIdx: number;
}) {
  const stopIdxPrefix = getStopIdxPrefix({ stopIdx });

  return {
    addressNameTestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_NAME_TEST_ID_PREFIX}`,
    addressLine1TestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_LINE_1_TEST_ID_PREFIX}`,
    addressLine2TestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_LINE_2_TEST_ID_PREFIX}`,
    addressCityTestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_CITY_TEST_ID_PREFIX}`,
    addressStateTestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_STATE_TEST_ID_PREFIX}`,
    addressZipTestId: `${stopIdxPrefix}-${ORDER_PAGE_ADDRESS_ZIP_TEST_ID_PREFIX}`,
  };
}

export function getStopFreightChargeComponentsTestIds({
  stopIdx,
}: {
  stopIdx: number;
}) {
  const stopIdxPrefix = getStopIdxPrefix({ stopIdx });

  return {
    freightChargeType: `${stopIdxPrefix}-${FREIGHT_CHARGE_TYPE_SELECT_TEST_ID}`,
    freightChargeRate: `${stopIdxPrefix}-${FREIGHT_CHARGE_RATE_SKELETON_TEST_ID}`,
  };
}

export function getStopTypeRadioButtonTestId({
  stopType,
  stopIdx,
}: {
  stopType: StopType;
  stopIdx: number;
}) {
  if (stopType === StopType.None) {
    const stopIdxPrefix = stopIdx === INBOUND_STOP_IDX ? 'INBOUND' : 'OUTBOUND';
    return `${stopIdxPrefix}-${ORDER_PAGE_STOP_TYPE_RADIO_BUTTON_TEST_ID_PREFIX}-none`;
  }

  return `${ORDER_PAGE_STOP_TYPE_RADIO_BUTTON_TEST_ID_PREFIX}-${stopType.toLowerCase()}`;
}

export function getMarkAsTestIds({ stopType }: { stopType: StopType }) {
  return {
    markAsCompleteSigneeInputTestId: `${stopType}-${ORDER_PAGE_MARK_AS_COMPLETE_MODAL_SIGNEE_INPUT_TEST_ID}`,
    markAsCompleteCompletedAtInputTestId: `${stopType}-${ORDER_PAGE_MARK_AS_COMPLETE_MODAL_COMPLETED_AT_INPUT_TEST_ID}`,
  };
}

export function getCompletedStopChipTestId({
  stopType,
}: {
  stopType: StopType;
}) {
  return `${stopType}-${ORDER_PAGE_COMPLETED_STOP_CHIP_TEST_ID}`;
}

export function mapTestOrderStopTypeToStopType({
  testOrderStopType,
}: {
  testOrderStopType: TestOrderStopType;
}): {
  inboundStopType: StopType;
  inboundMethod?: InboundMethod;
  outboundStopType: StopType;
  outboundMethod?: OutboundMethod;
} {
  switch (testOrderStopType) {
    case TestOrderStopType.Pickup: {
      return {
        inboundStopType: StopType.Pickup,
        outboundStopType: StopType.None,
      };
    }
    case TestOrderStopType.Delivery: {
      return {
        inboundStopType: StopType.None,
        outboundStopType: StopType.Delivery,
      };
    }
    case TestOrderStopType.PickupAndTransfer:
    case TestOrderStopType.PickupLineHaulTransfer: {
      return {
        inboundStopType: StopType.Pickup,
        outboundStopType: StopType.Transfer,
        outboundMethod: OutboundMethod.AirportTransfer,
      };
    }
    case TestOrderStopType.RecoveryAndDelivery:
    case TestOrderStopType.RecoveryLineHaulDelivery: {
      return {
        inboundStopType: StopType.Recovery,
        inboundMethod: InboundMethod.Recovery,
        outboundStopType: StopType.Delivery,
      };
    }
    case TestOrderStopType.PickupAndDelivery:
    case TestOrderStopType.PickupLineHaulDelivery: {
      return {
        inboundStopType: StopType.Pickup,
        outboundStopType: StopType.Delivery,
      };
    }
    case TestOrderStopType.PartnerCarrierDropoffAndDelivery:
    case TestOrderStopType.PartnerCarrierDropoffLineHaulDelivery: {
      return {
        inboundStopType: StopType.PartnerCarrierDropoff,
        inboundMethod: InboundMethod.InboundDelivery,
        outboundStopType: StopType.Delivery,
      };
    }
    case TestOrderStopType.PickupPartnerCarrierPickup:
    case TestOrderStopType.PickupPartnerCarrierLineHaulPickup: {
      return {
        inboundStopType: StopType.Pickup,
        outboundStopType: StopType.PartnerCarrierPickup,
        outboundMethod: OutboundMethod.LocalDelivery,
      };
    }
    default: {
      return exhaustive(testOrderStopType);
    }
  }
}

export function isPalletAdmin(email: string) {
  return email.endsWith('@trypallet.com') || email.endsWith('@withcashew.com');
}

const nonNegativeNumberPattern = /^\d+(\.\d+)?$/;
export const isValidNonNegativeNumber = (value: string): boolean =>
  nonNegativeNumberPattern.test(value);

const nonNegativeIntegerPattern = /^\d+$/;
export const isValidNonNegativeInteger = (value: string): boolean =>
  nonNegativeIntegerPattern.test(value);

/*
 * This pattern allows for partial or complete numbers (including decimals) when a user is still typing their input.
 * Examples of valid inputs: "", 123, 123., 123.4, .45, 123.45
 * Examples of invalid inputs: 123.., 123.4.5
 */
const partialOrCompleteNumberInputPattern = /^\d*\.?\d*$|^\.\d+$/;
export const isValidPartialOrCompleteNumberInput = (value: string): boolean =>
  partialOrCompleteNumberInputPattern.test(value);

/*
 * This pattern allows for partial or complete non-negative integers when a user is still typing their input.
 * Examples of valid inputs: "", 0, 1, 123
 * Examples of invalid inputs: 123., 123.4, .45, -123
 */
const partialOrCompleteNonNegativeIntegerPattern = /^\d*$/;
export const isValidPartialOrCompleteNonNegativeIntegerInput = (
  value: string,
): boolean => partialOrCompleteNonNegativeIntegerPattern.test(value);

/* HeadersInit is a built-in type that represents a group of headers and is a union of several types. This gets the value of a header by its name */
export const getHeaderByNameFromInit = (
  headersInit: HeadersInit,
  headerName: string,
): string | null => {
  // HeadersInit is a union of several types, so handle each case
  if (headersInit instanceof Headers) {
    return headersInit.get(headerName);
  }
  if (Array.isArray(headersInit)) {
    const header = headersInit.find((h) => h[0] === headerName);
    return header ? header[1] : null;
  }
  if (typeof headersInit === 'object') {
    return headersInit[headerName] ?? null;
  }
  return null;
};
