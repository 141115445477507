import { isNil } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useOrderForm from '../../orders/components/order-form/forms/use-order-form';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { type ThirdPartyUserCompany } from '../types';
import CustomerPortalOrderForm from './customer-portal-order-form/customer-portal-order-form';
import { useViewCustomerPortalOrderForm } from './customer-portal-order-form/hooks/use-view-customer-portal-order-form';

const CustomerPortalViewPendingOrderWithOrderUuid = ({
  orderUuid,
}: {
  readonly orderUuid: string;
}) => {
  const { loading, form, contactUuid } =
    useViewCustomerPortalOrderForm(orderUuid);

  const { thirdPartyUser } = useMeAsThirdPartyUser();

  if (loading) {
    return <CenteredCircularProgress />;
  }

  // To avoid polluting the form schema, we derive the company from
  // the selected contact UUID.
  const company: ThirdPartyUserCompany | null =
    thirdPartyUser?.contacts.find((c) => c.uuid === contactUuid)?.company ??
    null;

  if (isNil(company) || isNil(contactUuid)) {
    return <Navigate to="/customer-portal/orders" />;
  }

  return (
    <FormProvider {...form}>
      <CustomerPortalOrderForm
        disabled
        company={company}
        contactUuid={contactUuid}
      />
    </FormProvider>
  );
};

const CustomerPortalViewPendingOrder = () => {
  const { orderUuid } = useParams();
  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const { loading: thirdPartyUserLoading } = useMeAsThirdPartyUser();
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );
  // Also wait for third party user to load so we can check its feature flags
  if (statsigLoading || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  if (!ffCustomerPortalOrderEntry || isNilOrEmptyString(orderUuid)) {
    return <Navigate to="/customer-portal/orders" />;
  }

  return <CustomerPortalViewPendingOrderWithOrderUuid orderUuid={orderUuid} />;
};

export { CustomerPortalViewPendingOrder };
