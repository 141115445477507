import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { getOrganizationSchema } from './organization-schema';
import { type OrganizationFormValues } from './types';
import { useMemo } from 'react';
import { type FieldPath } from 'react-hook-form';
import { type OrganizationType } from '../../../generated/graphql';

const useOrganizationForm = ({
  organizationTypes,
}: {
  organizationTypes: OrganizationType[];
}) => {
  const schema = useMemo(
    () => getOrganizationSchema(organizationTypes),
    [organizationTypes],
  );

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
  });

  return useMemo(
    () => ({
      form,
      // this used to be the function from zod-utils
      // for now just return true because we stopped using it later in the stack
      isFieldRequired: (key: FieldPath<OrganizationFormValues>) => true,
      isFieldVisible: (key: FieldPath<OrganizationFormValues>) => true,
    }),
    [form],
  );
};

export default useOrganizationForm;
